<template>
  <a
    class="comv2_button-bar__link"
    :class="{
      'is_disabled': !isEnabled,
      'is_unauthorized': !isAuthorized,
    }"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    isEnabled: {
      type: Boolean,
      default: true,
    },

    isAuthorized: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div
    v-if="shouldShowDevtools"
    class="debug-bar-wrapper"
  >
    <div
      class="debug-bar"
    >
      <div
        class="debug-bar__header"
        @click="toggleDevtools"
      >
        <img
          src="/icon.png"
          class="debug-bar__header__logo"
        >

        <span
          v-if="hasDevtoolSettingActive"
        >
          ❗️
        </span>

        Planfred Devtools

        <PfIcon
          v-if="isExpanded"
          class="debug-bar__header__arrow-icon"
          name="chevronDown"
        />

        <PfIcon
          v-if="!isExpanded"
          class="debug-bar__header__arrow-icon"
          name="chevronUp"
        />
      </div>

      <AnimateExpand
        v-if="isExpanded"
      >
        <div
          class="debug-bar__content"
          :class="{
            'is_loading': state === validStates.IS_LOADING,
          }"
        >
          <ul
            class="debug-bar__list"
          >
            <li
              :class="{
                'list-element--is-active': isNetworkSlowdownActive,
              }"
            >
              <div>
                <a
                  v-if="isNetworkSlowdownActive"
                  @click="toggleIsNetworkSlowdownActive"
                >
                  Disable network delay
                </a>

                <a
                  v-if="!isNetworkSlowdownActive"
                  @click="toggleIsNetworkSlowdownActive"
                >
                  Enable network delay
                </a>
              </div>
            </li>

            <li
              v-click-away="closeSwitchHost"
              :class="{
                'list-element--is-active': !isLocalhostSettingActive,
              }"
            >
              <a
                @click="toggleSwitchHost"
              >
                Switch Host
              </a>

              <ul
                v-if="showHostSwitch"
              >
                <li
                  v-for="hostname in hostNames"
                  :key="hostname"
                  :class="{
                    'debug-bar__list__highlighted': hostname === currentHostname,
                  }"
                >
                  <template
                    v-if="hostname === currentHostname"
                  >
                    =>
                  </template>

                  <a @click="setHostName(hostname)">{{ hostname }}</a>
                </li>
              </ul>
            </li>

            <li>
              <a
                @click="onClickResetDb({ shouldResetBig: false })"
              >
                Reset data
              </a>
            </li>

            <li>
              <a
                @click="onClickResetDb({ shouldResetBig: true })"
              >
                Reset data big
              </a>
            </li>

            <li>
              <a
                @click="onClickClipboardFileUpload"
              >
                Clipboard file upload
              </a>
            </li>

            <li
              class="list-element--with-border list-element--margin-bottom"
              :class="{
                'list-element--is-active': isPrintModeActive,
              }"
            >
              <div>
                Print mode
              </div>

              <RadioSelection
                class="com_selection-box--row-sm-min com--sm"
                style="margin-top: 3px;"
              >
                <RadioSelectionEntry
                  :is-selected="isPrintModeActive"
                  @click="updatePrintMode(true)"
                >
                  on
                </RadioSelectionEntry>

                <RadioSelectionEntry
                  :is-selected="!isPrintModeActive"
                  @click="updatePrintMode(false)"
                >
                  off
                </RadioSelectionEntry>
              </RadioSelection>
            </li>

            <li
              class="list-element--margin-bottom"
              :class="{
                'list-element--is-active': customFontSize,
              }"
            >
              <div
                class="flex--centered"
              >
                Base font size

                <a
                  style="margin-left: 8px"
                  :class="{
                    'is_disabled': !customFontSize,
                  }"
                  @click="onClickResetFontSize"
                >
                  Reset
                </a>
              </div>

              <input
                ref="customFontSizeInput"
                type="text"
                :value="customFontSize || originalFontSize"
                class="custom-font-size-input"
                @input="updateFontSize($event.target.value)"
                @keydown.up.prevent="onKeyArrowUp"
                @keydown.down.prevent="onKeyArrowDown"
              >
            </li>
          </ul>

          <MessageBoxError
            v-if="state === validStates.HAS_ERROR"
          >
            Fehler
          </MessageBoxError>

          <div
            class="debug-bar__footer"
          >
            <a
              @click="close"
            >
              ausblenden
            </a>
          </div>
        </div>
      </AnimateExpand>
    </div>

    <DevModalClipboardUploader
      v-if="isClipboardModalOpen"
      @after:close-animation="isClipboardModalOpen = false"
    />
  </div>
</template>

<script>
import isOnLocalhost from '@client-shared/utils/is-on-localhost'
import { vOnClickOutside } from '@vueuse/components'

import AnimateExpand from '@/components/AnimateExpand.vue'
import DevModalClipboardUploader from '@/components/DevModalClipboardUploader.vue'
import MessageBoxError from '@/components/MessageBoxError.vue'
import PfIcon from '@/components/PfIcon.vue'
import RadioSelection from '@/components/RadioSelection.vue'
import RadioSelectionEntry from '@/components/RadioSelectionEntry.vue'

export default {
  directives: {
    clickOutside: vOnClickOutside,
  },

  components: {
    AnimateExpand,
    MessageBoxError,
    DevModalClipboardUploader,
    PfIcon,
    RadioSelection,
    RadioSelectionEntry,
  },

  data () {
    const validStates = Object.freeze({
      INITIAL: 1,
      IS_LOADING: 2,
      HAS_ERROR: 3,
    })

    return {
      state: validStates.INITIAL,
      validStates,

      // why? -> this.$config.debug.isNetworkSlowdownActive is not reactive
      isNetworkSlowdownActive: this.$config.debug.isNetworkSlowdownActive,
      currentHostname: window.localStorage.getItem('planfred_hostname'),
      hostNames: [
        'localhost',
        'backup.localhost',
        'app.planfred.com',
        'demo.planfred.com',
        'app.apg-planserver.at',
        'app.klg-planserver.de',
        'app.koupserver.at',
        'app.egv-planserver.de',
        'app.staabserver.com',
        'planfred.lsc-group.lu',
      ],

      showHostSwitch: false,
      showDebugBar: true,
      isExpanded: false,
      isClipboardModalOpen: false,
      isPrintModeActive: false,
      customFontSize: undefined,
      originalFontSize: undefined,
    }
  },

  computed: {
    shouldShowDevtools () {
      return this.showDebugBar && !window.Cypress && isOnLocalhost
    },

    isLocalhostSettingActive () {
      return this.currentHostname === 'localhost'
    },

    hasDevtoolSettingActive () {
      return this.isPrintModeActive || this.customFontSize || !this.isLocalhostSettingActive || this.isNetworkSlowdownActive
    },
  },

  methods: {
    toggleIsNetworkSlowdownActive () {
      // update reactive prop for UI
      this.isNetworkSlowdownActive = !this.isNetworkSlowdownActive
      // update $config
      this.$config.debug.isNetworkSlowdownActive = this.isNetworkSlowdownActive
    },

    setHostName (hostname) {
      window.localStorage.setItem('planfred_hostname', hostname)
      window.location.reload(true)
    },

    toggleSwitchHost () {
      this.showHostSwitch = !this.showHostSwitch
    },

    onResize () {
      if (!this.customFontSize) {
        this.resetOriginalFontSize()
      }
    },

    resetOriginalFontSize () {
      this.originalFontSize = window.getComputedStyle(document.querySelector('html')).getPropertyValue('--font-size-base')
    },

    closeSwitchHost () {
      this.showHostSwitch = false
    },

    close  () {
      this.showDebugBar = false
    },

    toggleDevtools () {
      this.isExpanded = !this.isExpanded

      if (this.isExpanded) {
        this.resetOriginalFontSize()

        // Focus font size input and set cursor on the start of the input
        this.$nextTick(() => {
          this.$refs.customFontSizeInput && this.$refs.customFontSizeInput.focus()
          this.$refs.customFontSizeInput && this.$refs.customFontSizeInput.setSelectionRange(0, 0)
        })

        window.addEventListener('resize', this.onResize)
      } else {
        window.removeEventListener('resize', this.onResize)
      }
    },

    updatePrintMode (newValue) {
      this.isPrintModeActive = newValue

      const cssClassNames = 'print-dev print-list-mode'

      if (this.isPrintModeActive) {
        this.$store.commit('layout/ADD_HTML_CLASS', cssClassNames)
      } else {
        this.$store.commit('layout/REMOVE_HTML_CLASS', cssClassNames)
      }
    },

    updateFontSize (fontSize) {
      this.customFontSize = fontSize
      document.documentElement.style.setProperty('--font-size-base', fontSize)
    },

    onClickResetFontSize () {
      this.customFontSize = undefined
      document.documentElement.style.removeProperty('--font-size-base')
    },

    onClickClipboardFileUpload () {
      this.isClipboardModalOpen = true
    },

    roundNumber (number) {
      const roundedNumber = Math.round((number + Number.EPSILON) * 100) / 100

      return roundedNumber
    },

    // Thanks to Chat GPT
    extractNumberAtIndex ({ text, index }) {
      const isPartOfNumber = char => /[0-9.-]/.test(char)
      let start = index
      let end = index

      // Traverse backwards to find the start of the number
      while (start > 0 && isPartOfNumber(text[start - 1])) {
        start--
      }

      // Traverse forwards to find the end of the number
      while (end < text.length && isPartOfNumber(text[end])) {
        end++
      }

      // Extract the number
      const numberString = text.substring(start, end)
      const numberParsed = numberString
        ? parseFloat(numberString)
        : undefined

      return {
        number: numberParsed,
        numberStartIndex: start,
        numberEndIndex: end,
      }
    },

    onKeyArrowUp (event) {
      let factorToAdd = 1

      if (event.shiftKey) {
        factorToAdd = 0.1
      }

      return this.handleInputKeyUpDown({
        event,
        updateFunction: number => number + factorToAdd,
      })
    },

    onKeyArrowDown (event) {
      let factorToAdd = 1

      if (event.shiftKey) {
        factorToAdd = 0.1
      }

      return this.handleInputKeyUpDown({
        event,
        updateFunction: number => number - factorToAdd,
      })
    },

    handleInputKeyUpDown ({ event, updateFunction }) {
      const inputText = event.target.value
      const cursorIndex = event.target.selectionStart
      const { number, numberStartIndex, numberEndIndex } = this.extractNumberAtIndex({ text: inputText, index: cursorIndex })

      if (!number) {
        return
      }

      const updatedNumber = this.roundNumber(updateFunction(number))

      const newCustomFontSize = inputText.substring(0, numberStartIndex) + updatedNumber + inputText.substring(numberEndIndex, inputText.length)

      this.updateFontSize(newCustomFontSize)

      this.$nextTick(() => {
        this.$refs.customFontSizeInput && this.$refs.customFontSizeInput.focus()
        this.$refs.customFontSizeInput && this.$refs.customFontSizeInput.setSelectionRange(cursorIndex, cursorIndex)
      })
    },

    async onClickResetDb ({ shouldResetBig = false }) {
      try {
        if (this.state === this.validStates.IS_LOADING) {
          return
        }

        this.state = this.validStates.IS_LOADING

        await this.$axios.post('development/devtools-actions/reset-db-big', {
          shouldResetBig,
        })

        this.state = this.validStates.INITIAL

        window.location.reload()
      } catch (err) {
        console.error(err)

        this.state = this.validStates.HAS_ERROR
      }
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.debug-bar-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  display: flex;
  justify-content: right;
  pointer-events: none;
}

.debug-bar {
  background-color: #eee;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-left: 1px solid #777;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  pointer-events: auto;

  &__header {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    padding: 0.25rem;

    &__arrow-icon {
      margin-left: 5px;
    }

    &__logo {
      width: 20px;
      margin-right: 5px;
    }
  }

  &__content {
    border-top: 1px solid #999;
    padding: 0.5rem;
  }

  &__list {
    li {
      list-style-type: disc;
      margin-left: 20px;
      margin-bottom: 2px;

      &.list-element--with-border {
        margin-top: 8px;
        border-top: 1px solid gray;
        padding-top: 8px;
      }

      &.list-element--margin-bottom {
        margin-bottom: 10px;
      }

      &.list-element--is-active {
        list-style-type: '❗️';
        margin-left: 15px;
        padding-left: 5px;
      }
    }

    &__highlighted {
      font-weight: bold;
    }

  }

  &__footer {
    margin-top: 5px;
    display: flex;
    justify-content: end;
  }
}

.debug-bar-close{
  text-align: right;
}

input.custom-font-size-input {
  padding: 5px 7px;
  margin-top: 3px;
}

.flex--centered {
  display:flex;
  align-items: center;
}

</style>

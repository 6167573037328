import * as TasksApi from '@client-shared/api/tasks.api.js'
import filterFunctions from '@client-shared/utils/filter-functions'
import getTaskSortFunction from '@client-shared/utils/get-task-sort-function'

import constants from '@/config/constants.js'

export default {
  namespaced: true,

  state: () => {
    return {
      list: [],
      markedTask: null,
      selectedMultiActionProperty: constants.MULTI_ACTION.TASK_ASSIGNEE,

      sortSettings: {
        sortProperty: constants.SORT_PROPERTIES.TASK_CONSECUTIVE_NUMBER,
        sortOrderReversed: false,
      },

      printSettings: {
        groupBy: constants.TASKS_LIST_GROUPING.GROUP_BY_NONE,
        showTaskUrl: false,
        showTaskDescription: false,
      },

      listSettings: {
        enableGroupBy: true,
      },

      // Only relevant for modus where tasks can be selected
      selection: {
        selectedTaskIds: [],
        shouldKeepSelected: true,
      },
    }
  },

  getters: {
    getById: (state) => {
      return taskId => state.list.find(task => task._id === taskId)
    },

    _listFilteredBySubTab: (state, getters, rootState) => {
      if (rootState.tasksListModus.subTab === constants.TASK_LIST_SUB_TABS.DRAFTS) {
        return getters.tasksDrafts
      }

      if (rootState.tasksListModus.subTab === constants.TASK_LIST_SUB_TABS.MAIN) {
        return getters.tasksMain
      }

      if (rootState.tasksListModus.subTab === constants.TASK_LIST_SUB_TABS.DONE) {
        return getters.tasksDone
      }

      if (rootState.tasksListModus.subTab === constants.TASK_LIST_SUB_TABS.TRASH) {
        return getters.tasksTrashed
      }

      return state.list
    },

    listFilteredByModus: (state, getters, rootState, rootGetters) => {
      const baseTasks = getters._listFilteredBySubTab
      const modus = rootState.tasksListModus.modus

      if (modus === constants.LIST_MODUS.UPDATE || modus === constants.LIST_MODUS.MULTI_ACTION_EDIT) {
        return baseTasks.filter(task => {
          return !task.trashed && rootGetters['acl/isAuthorizedToUpdateTask'](task)
        })
      }

      return baseTasks
    },

    listFiltered: (state, getters, rootState, rootGetters) => {
      if (!rootGetters['tasksListModus/filterStoreName']) {
        return getters.listFilteredByModus
      }

      const modus = rootState.tasksListModus.modus

      return getters.listFilteredByModus.filter(task => {
        if (modus === constants.LIST_MODUS.MULTI_ACTION_EDIT && state.selection.shouldKeepSelected && state.selection.selectedTaskIds.includes(task._id)) {
          return true
        }

        return filterFunctions.tasks.matchesFilters({
          task,
          project: rootState.project.project,
          ...rootState[rootGetters['tasksListModus/filterStoreName']],
        })
      })
    },

    listFilteredSorted: (state, getters) => {
      const listFiltered = getters.listFiltered || []

      return [...listFiltered].sort((a, b) => {
        return getters.groupFunction(a, b) || getters.sortFunction(a, b)
      })
    },

    tasksDrafts: (state, getters) => {
      return state.list.filter(task => task.state === constants.VALID_TASK_STATES.DRAFT)
    },

    tasksMain: (state, getters) => {
      return state.list.filter(task => task.state !== constants.VALID_TASK_STATES.DRAFT && task.state !== constants.VALID_TASK_STATES.DONE && !task.trashed)
    },

    tasksDone: (state, getters) => {
      return state.list.filter(task => task.state === constants.VALID_TASK_STATES.DONE && !task.trashed)
    },

    tasksTrashed: (state, getters) => {
      return state.list.filter(task => task.state !== constants.VALID_TASK_STATES.DRAFT && task.trashed)
    },

    canUseLocationLayerGrouping: (state, getters, rootState, rootGetters) => {
      const hasSelectableLayers = rootGetters['locationLayers/listSelectable'].length > 0
      const hasTasksWithLocationLayer = Boolean(state.list.find(task => task.locationLayerId))

      return hasSelectableLayers || hasTasksWithLocationLayer
    },

    usesLocationLayerGrouping: (state, getters, rootState, rootGetters) => {
      const tasksListModus = rootState.tasksListModus.modus
      const groupBy = state.printSettings.groupBy

      return (
        (tasksListModus === constants.LIST_MODUS.PRINT && groupBy === constants.TASKS_LIST_GROUPING.GROUP_BY_LOCATION_LAYER) ||
        (tasksListModus !== constants.LIST_MODUS.PRINT && state.listSettings.enableGroupBy && getters.canUseLocationLayerGrouping)
      )
    },

    usesTaskTypeGrouping: (state, getters, rootState) => {
      return rootState.tasksListModus.modus === constants.LIST_MODUS.PRINT && state.printSettings.groupBy === constants.TASKS_LIST_GROUPING.GROUP_BY_TYPE
    },

    getHighestTaskNumber: (state) => {
      const taskConsecutiveNumbers = state.list
        .map(task => task.consecutiveNumber)
        .filter(consecutiveNumber => consecutiveNumber)

      return Math.max(0, ...taskConsecutiveNumbers)
    },

    groupFunction: (state, getters, rootState, rootGetters) => {
      if (getters.usesTaskTypeGrouping) {
        return (a, b) => {
          if (a.type === b.type) { return 0 }

          return a.type > b.type ? 1 : -1
        }
      }

      if (getters.usesLocationLayerGrouping) {
        return (a, b) => {
          if (a.locationLayerId === b.locationLayerId) { return 0 }

          const aLocationLayer = rootGetters['locationLayers/getById'](a.locationLayerId)
          const bLocationLayer = rootGetters['locationLayers/getById'](b.locationLayerId)

          if (aLocationLayer && !bLocationLayer) { return -1 }
          if (!aLocationLayer && bLocationLayer) { return 1 }
          if (aLocationLayer.title === bLocationLayer.title) { return 0 }

          return aLocationLayer.title > bLocationLayer.title ? 1 : -1
        }
      }

      return () => 0
    },

    sortFunction: (state, getters, rootState) => {
      return getTaskSortFunction({
        sortProperty: state.sortSettings.sortProperty,
        sortOrderReversed: state.sortSettings.sortOrderReversed,
        project: rootState.project.project,
        selectedTaskIds: state.selection.selectedTaskIds,
      })
    },
  },

  mutations: {
    SET_TASKS (state, tasks) {
      state.list = tasks
    },

    REPLACE_TASK (state, updatedTask) {
      const existingTaskIndex = state.list.findIndex(task => task._id === updatedTask._id)

      if (existingTaskIndex !== -1) {
        const isUpdateNewer = new Date(updatedTask.modified) > new Date(state.list[existingTaskIndex].modified)

        if (isUpdateNewer) {
          state.list[existingTaskIndex] = updatedTask
        }
      }
    },

    ADD_TASK (state, task) {
      const existingIndex = state.list.findIndex(t => t._id === task._id)

      if (existingIndex < 0) {
        state.list.push(task)
        return
      }

      state.list[existingIndex] = task
    },

    REMOVE_TASK (state, taskId) {
      state.list = state.list.filter(task => task._id !== taskId)
    },

    RESET_TASKS (state) {
      state.list = []
    },

    SET_MARKED_TASK (state, task) {
      state.markedTask = task
    },

    RESET_SORTING (state) {
      state.sortSettings.sortProperty = constants.SORT_PROPERTIES.TASK_CONSECUTIVE_NUMBER
      state.sortSettings.sortOrderReversed = false
    },

    SET_SORT_PROPERTY (state, sortProperty) {
      state.sortSettings.sortProperty = sortProperty
    },

    SET_SORT_ORDER_REVERSED (state, sortOrderReversed) {
      state.sortSettings.sortOrderReversed = sortOrderReversed
    },

    SET_GROUP_BY (state, groupBy) {
      if (!Object.values(constants.TASKS_LIST_GROUPING).includes(groupBy)) {
        console.error(`${groupBy} is not a valid grouping mode for task list`)
        return
      }
      state.printSettings.groupBy = groupBy
    },

    SET_SHOW_TASK_URL (state, newValue) {
      state.printSettings.showTaskUrl = newValue
    },

    SET_SHOW_TASK_DESCRIPTION (state, newValue) {
      state.printSettings.showTaskDescription = newValue
    },

    TOGGLE_ENABLE_GROUP_BY (state) {
      state.listSettings.enableGroupBy = !state.listSettings.enableGroupBy
    },

    ADD_SELECTED_TASK_ID (state, taskId) {
      if (!state.selection.selectedTaskIds.includes(taskId)) {
        state.selection.selectedTaskIds.push(taskId)
      }
    },

    REMOVE_SELECTED_TASK_ID (state, taskId) {
      state.selection.selectedTaskIds = state.selection.selectedTaskIds.filter(id => id !== taskId)
    },

    SET_SHOULD_KEEP_SELECTED (state, shouldKeepSelected) {
      state.selection.shouldKeepSelected = shouldKeepSelected
    },

    RESET_SELECTION (state) {
      state.selection = {
        selectedTaskIds: [],
        shouldKeepSelected: true,
      }
    },

    SET_SELECTED_MULTI_ACTION_PROPERTY (state, multiActionProperty) {
      state.selectedMultiActionProperty = multiActionProperty
    },

    FULL_RESET (state) {
      state.list = []
      state.markedTask = null
      state.selectedMultiActionProperty = constants.MULTI_ACTION.TASK_ASSIGNEE

      state.sortSettings = {
        sortProperty: constants.SORT_PROPERTIES.TASK_CONSECUTIVE_NUMBER,
        sortOrderReversed: false,
      }

      state.printSettings = {
        groupBy: constants.TASKS_LIST_GROUPING.GROUP_BY_NONE,
        showTaskUrl: false,
        showTaskDescription: false,
      }

      state.listSettings = {
        enableGroupBy: true,
      }

      state.selection = {
        selectedTaskIds: [],
        shouldKeepSelected: true,
      }
    },
  },

  actions: {
    async fetch ({ commit }, { projectId }) { // On the web client we load all tasks for now
      const {
        tasks,
      } = await TasksApi.getTasks({
        axios: this.$axios,
        projectId,
        loadAll: true,
      })

      commit('SET_TASKS', tasks)
    },

    async fetchById ({ commit }, taskId) {
      const task = await TasksApi.getTask({
        axios: this.$axios,
        taskId,
      })

      commit('ADD_TASK', task)
    },

    async updateTaskTitle ({ commit }, { task, title }) {
      const updatedTask = await TasksApi.updateTaskTitle({
        axios: this.$axios,
        task,
        title,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskDescription ({ commit }, { task, description }) {
      const updatedTask = await TasksApi.updateTaskDescription({
        axios: this.$axios,
        task,
        description,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskType ({ commit }, { task, type }) {
      const updatedTask = await TasksApi.updateTaskType({
        axios: this.$axios,
        task,
        type,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskAssigneeUserId ({ commit }, { task, userId }) {
      const updatedTask = await TasksApi.updateTaskAssigneeUserId({
        axios: this.$axios,
        task,
        userId,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskDueDate ({ commit }, { task, dueDate }) {
      if (dueDate === task.dueDate) {
        return
      }

      const updatedTask = await TasksApi.updateTaskDueDate({
        axios: this.$axios,
        task,
        dueDate,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async publishTask ({ commit }, { task }) {
      const updatedTask = await TasksApi.publishTask({
        axios: this.$axios,
        task,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskLocations ({ commit, rootGetters }, { task, location1, location2, location3, location4, locationLayerId, locationPins }) {
      const updatedTask = await TasksApi.updateTaskLocations({
        axios: this.$axios,
        task,
        location1,
        location2,
        location3,
        location4,
        locationLayerId,
        locationPins,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskTags ({ commit, dispatch }, { item, newValue }) {
      const updatedTask = await TasksApi.updateTaskTags({
        axios: this.$axios,
        task: item,
        tags: newValue,
      })
      await dispatch('project/fetch', item.projectId, { root: true })

      commit('REPLACE_TASK', updatedTask)
    },

    async deleteTaskFiles ({ commit, rootGetters }, { task, fileIds }) {
      const updatedTask = await TasksApi.deleteTaskFiles({
        axios: this.$axios,
        taskId: task._id,
        fileIds,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async deleteTaskCommentFiles ({ commit, rootGetters }, { task, taskComment, fileIds }) {
      const updatedTask = await TasksApi.deleteTaskCommentFiles({
        axios: this.$axios,
        taskId: task._id,
        taskCommentId: taskComment._id,
        fileIds,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async reorderTaskFiles ({ commit, rootGetters }, { task, fileIds }) {
      const updatedTask = await TasksApi.reorderTaskFiles({
        axios: this.$axios,
        task,
        fileIds,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async reorderTaskCommentFiles ({ commit, rootGetters }, { task, taskComment, fileIds }) {
      const updatedTask = await TasksApi.reorderTaskCommentFiles({
        axios: this.$axios,
        task,
        taskComment,
        fileIds,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async deleteTask ({ commit, rootGetters }, { task }) {
      if (task.state === constants.VALID_TASK_STATES.DRAFT) {
        await TasksApi.deleteTaskHard({
          axios: this.$axios,
          task,
        })

        commit('REMOVE_TASK', task._id)
      } else {
        const updatedTask = await TasksApi.updateTrashed({
          axios: this.$axios,
          task,
          isTrashed: true,
        })

        commit('REPLACE_TASK', updatedTask)
      }
    },

    async restoreTask ({ commit, rootGetters }, { task }) {
      const updatedTask = await TasksApi.updateTrashed({
        axios: this.$axios,
        task,
        isTrashed: false,
      })

      commit('REPLACE_TASK', updatedTask)
    },

    async updateTaskCommentMessage ({ commit }, { task, taskComment, message }) {
      const updatedTask = await TasksApi.updateTaskCommentMessage({
        axios: this.$axios,
        task,
        taskComment,
        message,
      })

      commit('REPLACE_TASK', updatedTask)
    },
  },
}

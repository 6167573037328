<template>
  <div
    ref="richTextEditor"
    class="com_rich-text"
    :class="{
      'com_rich-text--upload-allowed': isUploadAllowed,
    }"
  >
    <label
      v-if="labelText !== ''"
      class="com_rich-text__label"
    >
      {{ labelText }}
    </label>

    <input
      :id="`trix-input-${randomId}`"
      :value="prefilledText"
      type="hidden"
      name="content"
    >

    <trix-editor
      ref="trix"
      class="com_rich-text__editor"
      :placeholder="placeholder"
      :input="`trix-input-${randomId}`"
      @trix-paste="onTrixPaste"
      @trix-focus="onTrixFocus"
      @trix-initialize="onTrixInitialize"
      @trix-change="onChangeText"
      @trix-before-initialize="onTrixBeforeInitialize"
      @trix-selection-change="onTrixSelectionChange"
      @trix-attachment-add="onTrixAttachmentAdd"
      @trix-file-accept="onTrixFileAccept"
    />

    <div
      class="com_rich-text__alert"
    >
      <MessageBoxError
        v-if="state === validStates.IMAGE_UPLOAD_SIZE_ERROR"
        data-testid="rich-text-editor-messageboxerror-image-size"
      >
        {{ $t('feature.account.user_signature.image_file_size_upload_error') }}
      </MessageBoxError>

      <MessageBoxError
        v-if="state === validStates.IMAGE_UPLOAD_ERROR"
        data-testid="rich-text-editor-messageboxerror-image-upload"
      >
        {{ $t('feature.account.user_signature.file_upload_error') }}
      </MessageBoxError>

      <MessageBoxError
        v-if="state === validStates.IMAGE_UPLOAD_TYPE_ERROR"
        data-testid="rich-text-editor-messageboxerror-image-type"
      >
        {{ $t('feature.account.user_signature.allowed_upload_file_types') }}
      </MessageBoxError>

      <MessageBoxInfo
        v-if="uploadType === 'signature'"
        data-testid="rich-text-editor-messageboxinfo-image-upload"
      >
        {{ $t('feature.account.user_signature.info_signature_image') }}
      </MessageBoxInfo>
    </div>
  </div>
</template>

<script>
import generateRandomId from '@client-shared/utils/crypto-random-uuid.js'
import AwsS3 from '@uppy/aws-s3'
import { Uppy } from '@uppy/core'
import DOMPurify from 'dompurify'
import Trix from 'trix'

import 'trix/dist/trix.css'

import MessageBoxError from '@/components/MessageBoxError.vue'
import MessageBoxInfo from '@/components/MessageBoxInfo.vue'

// taken from https://github.com/basecamp/trix/blob/a572f21bab3b20a6bd002bb52b52ba8914acddsrc/trix/core/helpers/dom.js
const removeNode = (node) => node?.parentNode?.removeChild(node)

export default {
  components: {
    MessageBoxInfo,
    MessageBoxError,
  },

  props: {
    uploadType: {
      type: String,
      default: '',
      validator: (value) => {
        return ['', 'signature', 'support'].includes(value)
      },
    },

    labelText: {
      type: String,
      default: '',
    },

    prefilledText: {
      type: String,
      default: '',
    },

    overrideText: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  emits: [
    'on-change',
    'on-selection-change',
  ],

  data () {
    const validStates = Object.freeze({
      INITIAL: 0,
      IMAGE_UPLOAD_PENDING: 1,
      IMAGE_UPLOAD_ERROR: 2,
      IMAGE_UPLOAD_SIZE_ERROR: 3,
      IMAGE_UPLOAD_TYPE_ERROR: 4,
    })
    return {
      state: validStates.INITIAL,
      validStates,
      randomId: generateRandomId(),
      uppy: null,
      trixHadFocus: false,
    }
  },

  computed: {
    trix () {
      return this.$refs.trix
    },

    trixEditor () {
      return this.trix.editor
    },

    isUploadAllowed () {
      return this.uploadType
    },
  },

  watch: {
    overrideText (newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }

      // if element is focussed don't update, because we don't want to replace content while user is in writing mode
      if (this.trixEditor.element === document.activeElement) {
        return
      }

      // patch endless loop when link popup is activated
      // link popup changes editor content
      if (document.querySelector('.trix-dialog--link.trix-active')) {
        return
      }

      this.trixEditor.setSelectedRange([0, this.trixEditor.getDocument().getLength()])
      this.trixEditor.insertHTML(newVal)
    },
  },

  beforeUnmount () {
    if (this.uppy) {
      this.uppy.destroy()
    }
  },

  created () {
    if (this.isUploadAllowed) {
      this.uppy = new Uppy({
        autoProceed: true,
      })
        .use(AwsS3, {
          shouldUseMultipart: true,
          limit: 5,
          endpoint: this.$config.api.companionUrl,
          headers: {
            Authorization: `Bearer ${this.$auth.user?.uploadToken}`,
            // 'uppy-auth-token': `Bearer ${this.$auth.user?.uploadToken}`,
          },
        })
        .on('upload-error', async (uppyFile, error) => {
          const responseStatusCode = error?.source?.status ? error.source.status : 0

          if (responseStatusCode === 401 && this.$auth.loggedIn) {
            await this.$auth.logout()
          }

          this.state = this.validStates.IMAGE_UPLOAD_ERROR
          // Remove attachment from preview in error case
          // https://github.com/basecamp/trix#inserting-a-content-attachment
          uppyFile.meta.attachment.remove()
        })
        .on('upload-progress', (uppyFile, progress) => {
          // Set upload progress on attachment
          const percentage = Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)
          uppyFile.meta.attachment.setUploadProgress(percentage)
        })
        .on('file-added', () => {
          this.state = this.validStates.INITIAL
        })
        .on('upload-success', (uppyFile) => {
          const key = uppyFile.s3Multipart.key
          // Set specific attributes for Trix editor replacement element
          const attributes = {
            url: `${this.$config.awsS3BucketHost}/${key}`,
          }
          uppyFile.meta.attachment.setAttributes(attributes)
          // Remove file from uppy after success
          this.uppy.removeFile(uppyFile.id)
        })
    }
  },

  methods: {
    onTrixInitialize (e) {
      const trix = e.target

      const toolBar = trix.toolbarElement

      if (!toolBar) {
        return
      }

      const button = document.createElement('button')
      button.setAttribute('type', 'button')
      button.setAttribute('class', 'trix-button trix-button--icon trix-button--icon-underline')
      button.setAttribute('data-trix-attribute', 'underline')
      button.setAttribute('title', 'underline')
      button.setAttribute('tabindex', '-1')
      button.setAttribute('data-testid', 'trix-custom-button-underline')
      button.textContent = 'U'

      // set shortcut
      // https://github.com/basecamp/trix/issues/643
      button.setAttribute('data-trix-key', 'u')

      // Attachment of the button to the toolBar
      const textToolBar = toolBar.querySelector('.trix-button-group--text-tools')
      const textToolBarButtonItalic = toolBar.querySelector('.trix-button--icon-italic')
      textToolBar.insertBefore(button, textToolBarButtonItalic.nextSibling)
    },

    onTrixFocus () {
      this.trixHadFocus = true
    },

    onTrixFileAccept (e) {
      if (!this.isUploadAllowed) {
        e.preventDefault()
      }
    },

    onTrixPaste (data) {
      if (!data.paste.html) {
        return
      }

      this.trixEditor.setSelectedRange(data.paste.range)
      this.trixEditor.deleteInDirection('backward')

      this.trixEditor.insertHTML(DOMPurify.sanitize(data.paste.html, { ALLOWED_TAGS: this.$config.sanitizeAllowedTagsSignature }))
    },

    onChangeText (event) {
      if (this.trixHadFocus) {
        this.$emit('on-change', event.target.value)
      }
    },

    onTrixSelectionChange () {
      // Outputting the fragment content using a throwaway intermediary DOM element (div):
      const div = document.createElement('div')
      div.appendChild(window.getSelection().getRangeAt(0).cloneContents())

      this.$emit('on-selection-change', {
        selectedText: div.innerHTML,
        cursorPosition: this.trixEditor.getSelectedRange(),
      })
    },

    onTrixBeforeInitialize () {
      // Overwrite trix pickFiles method to restrict MIMETypes with file picker
      if (this.isUploadAllowed) {
        // disable captions and file size
        Trix.config.attachments.preview.caption.name = false
        Trix.config.attachments.preview.caption.size = false
        Trix.config.attachments.file.caption.size = false

        const fileInputId = Trix.config.input.fileInputId
        const allowedTypesList = this.$config.constants.SIGNATURE_IMAGE_ALLOWED_MIME_TYPES.toString()

        Trix.config.input.pickFiles = cb => {
          const input = document.createElement('input')
          input.setAttribute('id', fileInputId)
          input.setAttribute('type', 'file')
          input.setAttribute('hidden', 'true')
          input.setAttribute('multiple', 'true')
          input.setAttribute('accept', allowedTypesList)

          input.addEventListener('change', () => {
            cb(input.files)
            removeNode(input)
          })

          removeNode(document.getElementById(fileInputId))
          this.$refs.richTextEditor.appendChild(input) // Append it to the current richTextEditor instance, because adding it to the root would cause the modal to disappear on click (as the button would be placed outside of the modal). Check this issue for reference: https://3.basecamp.com/4480613/buckets/16502492/todos/6412906020
          input.click()
        }
      }

      // CONFIGURE TRIX EDITOR
      // Trix Editor Underline Button
      // https://github.com/basecamp/trix/pull/473
      Trix.config.textAttributes.underline = {
        tagName: 'u',
        inheritable: true,
        parser (e) {
          const style = window.getComputedStyle(e)
          return style.fontStyle === 'underline'
        },
      }
    },

    onTrixAttachmentAdd (e) {
      // https://github.com/basecamp/trix#storing-attached-files
      // https://github.com/basecamp/trix#inserting-a-file
      // https://trix-editor.org/js/attachments.js

      if (!this.isUploadAllowed) {
        return
      }
      const { attachment } = e

      // when copying picture from editor to editor the first fired event has no file
      if (!e.attachment.file) {
        return
      }

      const file = e.attachment.file

      if (!this.$config.constants.SIGNATURE_IMAGE_ALLOWED_MIME_TYPES.includes(file.type)) {
        attachment.remove()
        this.state = this.validStates.IMAGE_UPLOAD_TYPE_ERROR
        return
      }

      //  100kb file size limit
      if (this.uploadType === 'signature') {
        if (file.size > this.$config.constants.SIGNATURE_IMAGE_FILE_SIZE_LIMIT) {
          attachment.remove()
          this.state = this.validStates.IMAGE_UPLOAD_SIZE_ERROR
          return
        }
      }

      const generateDownloadFileName = originName => {
        const encoded = encodeURIComponent(originName)
        const nameArr = encoded.split('.')
        const extension = nameArr.pop()
        nameArr.push(`--${generateRandomId()}.${extension}`)
        return nameArr.join('')
      }

      const uploadFile = {
        name: generateDownloadFileName(file.name),
        type: file.type,
        data: file,
        meta: {
          userId: this.$auth.user._id,
          filesize: file.size,
          qqfilename: encodeURIComponent(file.name),
          isPublic: ['signature', 'support'].includes(this.uploadType),
          operation: this.uploadType,
          attachment: e.attachment, // we need the attachment to set the upload progressbar for trix
        },
      }

      try {
        this.state = this.validStates.IMAGE_UPLOAD_PENDING

        this.uppy.addFile(uploadFile)
      } catch (e) {
        console.error(e)

        this.uppy.destroy()
      } finally {
        this.state = this.validStates.INITIAL
      }
    },
  },
}
</script>

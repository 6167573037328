<template>
  <button
    class="obj_x-close-button"
    :class="{
      'obj--2xs': size === '2xs',
      'obj--xs': size === 'xs',
      'obj--sm': size === 'sm',
      'obj--ml': size === 'ml',
      'obj--lg': size === 'lg',
      'obj--xl': size === 'xl',
      'obj--2xl': size === '2xl',
      'obj--3xl': size === '3xl',
      'obj--2xs-mobile': mobileSize === '2xs',
      'obj--xs-mobile': mobileSize === 'xs',
      'obj--sm-mobile': mobileSize === 'sm',
      'obj--md-mobile': mobileSize === 'md',
      'obj--lg-mobile': mobileSize === 'lg',
      'obj--xl-mobile': mobileSize === 'xl',
      'obj--2xl-mobile': mobileSize === '2xl',
      'obj_x-close-button--white': isWhite,
    }"
  >
    <span
      v-if="showLabel"
      class="obj_x-close-button__text"
    >
      <template v-if="label">{{ label }}</template>

      <template v-if="!label">{{ $t('layout.close') }}</template>
    </span>

    <span
      class="obj_x-close-button__icon"
    >
      <PfIcon
        name="closeCircle"
      />
    </span>
  </button>
</template>

<script>
export default {
  props: {

    size: {
      type: String,
      default: '',
    },

    mobileSize: {
      type: String,
      default: '',
    },

    isWhite: {
      type: Boolean,
      default: false,
    },

    showLabel: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },
  },
}
</script>

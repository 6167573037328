import constants from '@/config/constants'
import i18nInstance from '@/plugins/i18n'

const $i18n = i18nInstance.global

export default ({ $axios, app }) => {
  $axios.interceptors.request.use(async (config) => {
    const $auth = app.config.globalProperties.$auth

    // if Authorization header is set to null, remove it from axios request header
    if (config.headers.Authorization === null || !$auth.accessToken) {
      delete config.headers.Authorization
    } else if ($auth.accessToken) {
      config.headers.Authorization = `Bearer ${$auth.accessToken}`
    }

    // Send Accept-Language header with every request
    // API needs to know client language when user is not logged in / on public route
    config.headers['Accept-Language'] = $i18n.locale || 'de'
    config.headers['X-App-Platform'] = constants.PLATFORMS.WEB_APP

    return config
  })
}
